import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Blogs from '../components/Blogs/Blogs'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'
import { LISTINGS } from '../components/internal-links'
import { LISTINGS as LISTINGS_DOWNLOAD_LINK } from '../components/download-links'
import LISTINGS_TAGS from '../listings-tags'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Listings',
    url: LISTINGS,
  },
]

const LearnListings: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={LISTINGS_DOWNLOAD_LINK}
    >
      <SEO
        title="Listings: Its types, strategies, methods, and gameplay | Mega"
        description="Get to know more about listings. We have listed 15+ articles for you. Read now."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="listings"
        tags={LISTINGS_TAGS}
        prefix={`/${process.env.GATSBY_CMS_LISTINGS_PREFIX}` || '/'}
        title={
          <>
            Listings - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnListings
